//this is the file with only wallet installed 
require('dotenv').config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey); 
const OctoHedzABI = require('../contract-abi.json')
const INKzABI = require('../INKz-Contract-abi.json')
const KrakenABI = require('../Kraken-Contract-abi.json')
const Kraken_PRICE = "600000000000000000000"; //600 INKz

//Mainnet
//export const OctoHedzAddress = "0x6E5a65B5f9Dd7b1b08Ff212E210DCd642DE0db8B";
//export const INKzAddress = "0xcd7ce5d63f7dc7b1076f64e6c1dd7ccfa31228c7";
//export const KrakenAddress = "0xF92cF4a3776bA3F6a3eD96E1974D38Fcf59307f6";

export const OctoHedzAddress = "0x9914eA7d64f87301B1195B583d4A6395B24D04A9";
export const INKzAddress = "0xf05a45A13F68dAc889d166A3E69DfeF73d59F19B";
export const KrakenAddress = "0x2E8FF05D1ABd768B0706c69a428d218AFD8168b2";


export const connectWallet = async () => {


    if (window.ethereum) {
      //if (window.ethereum.chainId !== "0x1") {  //mainnet
      if (window.ethereum.chainId !== "0x4") { 

        return {
            address: "",
            status: (<div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
                <div>Please make sure <br></br> you're connected to <br></br> the Ethereum mainnet</div>
            </div>)
        };
    }
    try {
        const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
        });
        const obj = {
            status: "",
            address: addressArray[0],
        };
        return obj;
    } catch (err) {
        return {
            address: "",
            status: (<div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
                <div>Something went wrong, please make sure you're connected on the Ethereum mainnet
                </div>
            </div>)
        };
    }
} 
else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };
  export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "You are Connected to the Network",
          };
        } else {
          return {
            address: "",
            status: "🦊 Connect to Metamask using the top right button.",
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  export const getMintedKrakens = async () => {

    window.contract = await new web3.eth.Contract(KrakenABI, KrakenAddress);

    return {
        MintedKrakens: await window.contract.methods.totalSupply().call()
    }
}


export const getOctoHedzinWallet = async (address) => {

  window.contract = await new web3.eth.Contract(OctoHedzABI, OctoHedzAddress);
  
  return {
    
    OctoHedzinWallet: await window.contract.methods.balanceOf(address).call()
  }
}



export const getMintedOctos = async () => {

    window.contract = await new web3.eth.Contract(KrakenABI, KrakenAddress);

    return {
        mintedOctos: await window.contract.methods.totalSupply().call()
    }
}

export const getTotalINKz = async () => {

  window.contract = await new web3.eth.Contract(INKzABI, INKzAddress);

  return {
    totalInkz: await window.Contract.methods.getTotalClaimable().call()
  }
}


  export const getSaleIsActive = async () => {

    window.contract = await new web3.eth.Contract(KrakenABI, KrakenAddress);

    return {
        saleIsActive: await window.contract.methods.saleIsActive().call()
    }
}

export const mintNFT = async (quantity) => {

  if (quantity > 8) {
      return {
          success: false,
          status: <div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
              <div>Number of minted OctoHedz <br></br> should be 8 or less</div>
          </div>
      }
  }

  if (quantity <= 0) {
      return {
          success: false,
          status: <div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
              <div>Number of minted OctoHedz <br></br> should at least 1</div>
          </div>
      }
  }

  window.contract = await new web3.eth.Contract(KrakenABI, KrakenAddress);


  const price = Number(Kraken_PRICE) * quantity;
  // set up your Ethereum transaction
  const transactionParameters = {
      to: KrakenAddress, // Required except during contract publications.
      from: window.ethereum.selectedAddress, // must match user's active address.
      data: window.contract.methods.mintWithINKz(Number(quantity)).encodeABI(), //make call to NFT smart contract
      value: price.toString(16)
  };

  

  //sign the transaction via Metamask
  try {
      const txHash = await window.ethereum
          .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
          });

      var transactionUrl = "https://etherscan.io/tx/" + txHash

      return {
          success: true,
          status: (<div className="af-class-success-message w-form-done" style={{ display: "block" }}>
              <div className="af-class-text-block limit-overflow">Thank you! <br />Check your transaction on <br /> etherscan <br /><a href={transactionUrl} target="_blank" rel="noreferrer">{transactionUrl}</a></div>
          </div>)
      }
  } catch (error) {
      return {
          success: false,
          status: <div className="af-class-error-message w-form-fail" style={{ display: "block" }}>
              <div>Something went wrong<br /> {error.msg}</div>
          </div>
      }

  }
  
}

