import { useEffect, useState } from "react";
import { ethers, Contract, BigNumber } from 'ethers';
//import { useSelector } from 'react-redux';
import logo from "./V2-Octos.gif";
import breed from "./breed.gif"
import {
  connectWallet,
  getCurrentWalletConnected,
  getMintedKrakens,
  getOctoHedzinWallet,
  getTotalINKz,
  mintNFT,
  OctoHedzAddress,
  INKzAddress,
  KrakenAddress,
  getSaleIsActive 
} from "./utils/interact.js";
import { OctoHedzABI } from './OctoHedzABI.js'; 
import { INKzABI } from './INKzABI.js'; 
import {KrakenABI} from './KrakenABI.js'




const ClaimINKz = (props) => {
 
  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [MintedKrakens, setMintedKrakens] = useState('..');
  const [OctoHedzinWallet, setOctoHedzinWallet] = useState('..');
   const [clickable, setClickable] = useState(false)
  const [claim, setClaim] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [mintedPercentage, setMintedPercentage] = useState('..');
  const [inkzNumber, setINKzNumber] = useState(1);
  const [saleIsActive, setSaleIsActive] = useState(false)
  const [quantity, setQuantity] = useState('1');
  const [showForm, setShowForm] = useState(true);
  const [estimatedCost, setEstimatedCost] = useState('600');
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  const signer = provider.getSigner();
  const maxInt = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
  const INKz = new Contract(INKzAddress, INKzABI, signer);
  const Kraken = new Contract(KrakenAddress, KrakenABI, signer);
  const OctoHedz =  new Contract(OctoHedzAddress, OctoHedzABI, signer);
  const getAddress = async() => { try { return await signer.getAddress(); } catch { return false; }};

  function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }
 
/*
  const waitForTransaction = async() => {
};
*/

//--------------------*************************WALLET CODE**********************------------------------

  useEffect(async () => {
    
    const {address, status} = await getCurrentWalletConnected();
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    setWallet(address)
    setStatus(status);
    const { MintedKrakens } = await getMintedKrakens();
    console.log(MintedKrakens)
    setMintedKrakens(MintedKrakens);
    setMintedPercentage(round(MintedKrakens / 4000 * 100, 0));

    const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
    if (INKzAddress) {
      let claimableTokens = ethers.utils.formatEther(await getClaimableTokens(address))
      let inventoryTokens = ethers.utils.formatEther(await getInventoryTokens(address))
      setClaim(claimableTokens)
      setInventory(inventoryTokens)
      if (claimableTokens > 0) {
        setClickable(true);
      }
  
    }
    
    const { OctoHedzinWallet } = await getOctoHedzinWallet(address);  
    console.log(OctoHedzinWallet)
    setOctoHedzinWallet(OctoHedzinWallet);

}, []);

window.onload = function() {
  if(!window.location.hash) {
      window.location = window.location + '#loaded';
      window.location.reload();
  }
}


  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("You are Connected to the Network");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top right button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
  }
}

  const connectWalletPressed = async () => {
  const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

const inkzButtonClass = () => {
  let cls = "btn btn-orange btn-lg rounded-pill px-5 py-3 fs-4";
  
}
const renderINKzButtonText = () => {
  return `CLAIM $INKz`; 
}





//Claim 

const getClaimableTokens = async (walletID) => {
  const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
  const contract = new Contract(INKzAddress, INKzABI, signer);
  let tokenCount = 0;
  console.log(walletAddress)
  try {
    tokenCount = await contract.getTotalClaimable(walletID)
  } catch (err) {
    tokenCount = -1;
    console.error(err)
  }
  return tokenCount;
}

const getInventoryTokens = async (walletID) => {
  const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
  const contract = new Contract(INKzAddress, INKzABI, signer);
  let tokenCount = 0;
  try {
    tokenCount = await contract.balanceOf(walletID);
  } catch (err) {
    tokenCount = -1;
    console.error(err)
  }
  return tokenCount; 
  
}




const claimTokens = async () => {
  try {

  const signer = (new ethers.providers.Web3Provider(window.ethereum)).getSigner()
  const contract = new Contract(INKzAddress, INKzABI, signer);
  await contract.connect(signer)
			const value = BigNumber.from('80000000000000000').mul(inkzNumber); 
			await contract.claimReward()


  } catch(err) {
        if (err.code === 4001) {
          alert('Minting cancelled')
        } else {
          alert("Not Enough INKz to Claim");
        }
      }
};

const truncate = (address) => {
  address = String(address)
  if (address.length <= 15) return address;
  return address.substr(0, 6) + "..." + address.substr(address.length - 4);
};

const truncateDecimals = (number, digits) => {
  var multiplier = Math.pow(10, digits),
    adjustedNum = number * multiplier,
    truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};

//-------------------*******************BREEDING CODE*********************------------------//



const INKzApproveKraken = async() => {
  try{

  await INKz.approve(KrakenAddress, maxInt);
} catch(err) {
  if (err.code === 4001) {
    alert('Minting cancelled')
  } else {
    alert("Please connect your wallet");
  }
}
};



const mintButtonClass = () => {
  let cls = "btn btn-orange btn-lg rounded-pill px-5 py-3 fs-4";
  
}
const renderMintButtonText = () => {
  return `Breed Kraken  ${(600 * mintNumber).toFixed(2)} $INKz`; 
}



const _onMintPressed = async(amount) => {
  
  await Kraken.mintWithINKz(amount, { value : "0", gasLimit: 257000 * mintNumber });
};
const onMintPressed = async() => { 
  if (OctoHedzinWallet > 1){
  
  try{
   await _onMintPressed(mintNumber);} catch(err) {
    if (err.code === 4001) {
      alert('Minting cancelled')
    } else {
      alert("Please connect your wallet");
    }
  }
  }else {
    alert("Don't have enough Genesis OctoHedz to Breed");}
};


  const updateQuantityAndEstimatedCost = async (quantity) => {
    if (quantity.length == 0) {
      setEstimatedCost(0)
      setQuantity(quantity)
      return;
    }
    quantity = Math.min(quantity, 8)
    setQuantity(quantity)
    setEstimatedCost(round(quantity * 0.08, 8))
  };


  const [mintNumber, setMintNumber] = useState(1);

  const mintNumberChanged = (e) => {
  const num = Math.max(Math.min(e.target.value % 10, "8"), 1)
          setMintNumber(num);
        }


//--------------------*************************CSS**********************------------------------
  return (
    <div className="ClaimINkz">
    
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>
     




      <br></br>
      <br></br>
      <br></br>    
      <br></br>
      <h1 id="title">OctoHedz Utility Token</h1>
      <p>Genesis OctoHedz Yield 8 $INKz / day</p>
      <div className="row mt-3 mt-md-4 mt-lg-5" data-aos="fade-up" data-aos-once="true" data-aos-delay="300">
						  <div className="col-sm-12 col-md-9 bg-secondary rounded-3 p-3"></div>
              <div className="mb-3">
								<label htmlFor="mint_amount" className="form-label"></label>
			  	
          </div>
          </div>
          <div className="text-center mt-5" data-aos="fade-up" data-aos-once="true" data-aos-delay="600">
						<button className={inkzButtonClass()}
							onClick={()=>claimTokens()}>
							Claim {truncateDecimals(claim, 2)} $INKz
						</button>
					</div> 
          <p></p>
          <div className="af-class-bar-graph-content">
              <div className="af-class-bar-graph-information">
              <div className="claim-wrapper">
                </div>    

              </div>
            </div>
            <h1 id="title">Baby Kraken Claim</h1>
      <p>Need two Genesis to breed </p>
      <p>You currently have  {OctoHedzinWallet} </p>
      <div className="text-center mt-5" data-aos="fade-up" data-aos-once="true" data-aos-delay="600">

            <br></br>
            <br></br>
            <br></br>
      <img id="breed" src={breed}></img>
					</div> 
          <div className="text-center mt-5" data-aos="fade-up" data-aos-once="true" data-aos-delay="600">

						<button className={mintButtonClass()}
							onClick={()=>onMintPressed()}>
							{renderMintButtonText()}
						</button>
            
					</div> 
          <p id="status">
        {status}
        </p>
        <div className="af-class-text-block-2">Total minted {MintedKrakens} / 4000</div>
        <div className="af-class-text-block-2">Current $INkz in your wallet {truncateDecimals(inventory, 2)}</div>
        <p>Make sure to refresh page to view Token claim</p>
      <br></br>
      
      
    </div>
  );
};

export default ClaimINKz;