import './App.css';
import ClaimINKz from './ClaimINKz'

function App() {
  return (
    <div className="App">
      <ClaimINKz></ClaimINKz>
    </div>
    
  );
}

export default App;
